import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../../assets/images/wjt_logo.png";
import { DB } from '../../store/store';


// Create styles
const styles = StyleSheet.create({
    details_wrapper_main: {
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 400,
        fontStyle: 'normal',
        width: '100%',
    },
    details_wrapper: {
        padding: 25,
        fontWeight: 400,
        fontStyle: 'normal',
        width: '100%',
    },
    page: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    section: {
        width: '100%',
        textAlign: 'center'
    },
    logo: {
        width: '35%',
        margin: '0 auto'
    },
    details_title: {
        marginBottom: 10,
        marginTop: 10,
        borderBottom: '2px solid #000',
    },
    details_title2: {
        marginBottom: 10,
        marginTop: 10,
    },
    details_titleH1: {
        margin: '0 auto 10px 0',
        color: '#000000',
        fontWeight: 700,
        fontStyle: 'normal',
        fontSize: '25px',
        textAlign: 'left'
    },
    details_titleH3: {
        margin: '0 0 10px',
        color: '#000000',
        fontWeight: 700,
        fontStyle: 'normal',
        fontSize: '16px',
    },
    details_outer_wrapper: {
        padding: '10px 0 5px',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    details_outer_wrapper1: {
        borderTop: '1px solid #ccc',
        padding: '10px 0 5px',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    view_category_name: {
        width: '100%',
    },
    details_first_column: {
        paddingRight: '10px',
        fontSize: '10px',
        color: '#000000',
        fontWeight: 600,
        fontStyle: 'normal',
        width: '200px',
    },
    details_second_column: {
        marginRight: '5px',
        display: 'flex',
        flexDirection: 'row',
        width: '350px',
        marginBottom: '5px'
    },
    details_third_column: {
        marginBottom: '0.5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '210px',
    },
    details_first_column_text: {
        fontSize: '12px',
    },
    details_third_column_text: {
        color: '#0b0b0b',
        fontWeight: '400',
        fontSize: '10px',
        width: '90%',
    },
    section_img: {
        margin: '0 auto',
        width: '60%',
    },
    pageWrapper: {
        paddingTop: 45,
        paddingRight: 20,
        paddingLeft: 20,
    },
    firstPageWrapper: {
        paddingBottom: 45,
    },
});

// Create Document Component
const AttachPDF = ({ objs, img }) => {
    let optionTitleFlag = false;

    return (
        <Document>
            <Page wrap size="A4" style={[styles.pageWrapper, styles.firstPageWrapper]}>
                <View style={styles.details_wrapper_main}>
                    <View style={styles.page}>
                        <View style={styles.section}>
                            {/* <Image src={logo} /> */}
                            <Image style={styles.logo} src={{ uri: logo, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} />
                        </View>
                    </View>
                    <View style={styles.section_img}>
                        {/* <Image src={img} /> */}
                        <Image src={{ uri: img }} />
                    </View>
                </View>

                <View style={styles.details_wrapper}>
                    <View style={styles.details_title}>
                        <Text style={styles.details_titleH1}>
                            {objs.models[0].name}
                        </Text>
                    </View>

                    <View style={styles.details_title2}>
                        <Text style={styles.details_titleH3}>
                            Details
                        </Text>
                    </View>

                    <View wrap>
                    {
                        objs.models[0].nodes.map((m, i) => {
                            optionTitleFlag = true;
                            return(
                                m.options.map((n, j) => (
                                    n.child.map((o, k) => {
                                        if(m.id !== 8 && o.active === true){
                                            return(
                                                <View key={`${i}-${j}-${k}`} style={optionTitleFlag ? styles.details_outer_wrapper1 : styles.details_outer_wrapper}>
                                                    <View style={styles.details_first_column}>
                                                        <Text style={styles.details_first_column_text}>
                                                            {optionTitleFlag ? m.node_name : ''}
                                                            {optionTitleFlag = false}
                                                        </Text>
                                                    </View>
                                                    
                                                    <View style={styles.details_second_column}>
                                                        <View style={styles.view_category_name}>
                                                            <Text style={styles.details_third_column_text}>
                                                                {n.name}
                                                            </Text>
                                                        </View>
                                                        <View style={styles.details_second_column} >
                                                            <View style={styles.details_third_column}> 
                                                                <Text style={styles.details_third_column_text}>
                                                                    {o.name}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                            )
                                        }
                                    })
                                ))
                            )
                        })
                    }
                    </View>

                    {objs.models[0].name.split(" ")[0] === "SOLAS" &&
                        <View style={styles.details_outer_wrapper1}>
                            <View style={styles.details_first_column}>
                                <Text style={styles.details_first_column_text}>
                                    Rescue mode
                                </Text>
                            </View>
                            
                            <View style={styles.details_second_column}>
                                <View style={styles.view_category_name}>
                                    <Text style={styles.details_third_column_text}>
                                        {objs.models[0].nodes[7].options[0].name}
                                    </Text>
                                </View>
                                <View style={styles.details_second_column} >
                                    <View style={styles.details_third_column}> 
                                        <Text style={styles.details_third_column_text}>
                                        {objs.models[0].nodes[7].options[0].child[0].active ? "ON" : "OFF"}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    }
                    
                </View>
            </Page>
        </Document>
    )
}

export default AttachPDF