import { getData2, postData } from "../api/api";
import { DB } from "../store/store";

export function updateModel(data){
    DB.models[0].name = data?.title.rendered;
    DB.models[0].config_image = data?.acf["td_specification"].td_base_image;
    DB.models[0].dimensions = data?.acf["td_specification"].td_typical_fitment.replace('Yachts ', '');
    DB.models[0].capacity = data?.acf["td_specification"].td_seating.replace(' persons', '');
    
    DB.models[0].specifications[0].beam = data?.acf["td_specification"].td_beam;
    DB.models[0].specifications[0].engine = data?.acf["td_specification"].td_engine;
    DB.models[0].specifications[0].fuel_capacity = data?.acf["td_specification"].td_fuel_capacity;
    DB.models[0].specifications[0].loa = data?.acf["td_specification"].td_loa;
    DB.models[0].specifications[0].max_speed = data?.acf["td_specification"].td_max_speed;
    DB.models[0].specifications[0].overall_height = data?.acf["td_specification"].td_overall_height;
    DB.models[0].specifications[0].seating = data?.acf["td_specification"].td_seating;
    DB.models[0].specifications[0].typical_fitment = data?.acf["td_specification"].td_typical_fitment;
    DB.models[0].specifications[0].dry_weight = data?.acf["td_specification"].td_dry_weight;

    DB.models[0].nodes[0].options[0].child = [];
    DB.models[0].nodes[1].options[0].child = [];
    DB.models[0].nodes[1].options[2].child = [];
    DB.models[0].nodes[2].options[0].child = [];
    DB.models[0].nodes[3].options[0].child = [];
    DB.models[0].nodes[3].options[1].child = [];
    DB.models[0].nodes[3].options[2].child = [];
    DB.models[0].nodes[3].options[3].child = [];
    DB.models[0].nodes[4].options[0].child = [];
    DB.models[0].nodes[5].options[0].child = [];

    DB.models[0].nodes[0].active = false;
    DB.models[0].nodes[1].active = false;
    DB.models[0].nodes[2].active = false;
    DB.models[0].nodes[3].active = false;
    DB.models[0].nodes[4].active = false;
    DB.models[0].nodes[5].active = false;
    DB.models[0].nodes[6].active = false;

    // if(data?.acf.rescue_mode){
    //     DB.models[0].nodes[7].active = true;
    //     DB.models[0].nodes[7].options[1].child[1].active = true;
    // }
    // else{
    //     DB.models[0].nodes[7].options[1].active = false;
    // }

    if(data?.acf.rescue_mode_image){
        DB.models[0].nodes[7].active = true;
        console.log(DB.models[0].nodes[7].options[0]);

        DB.models[0].nodes[7].options[0].child[0].config_img = data?.acf.rescue_mode_image.url;
        DB.models[0].nodes[7].options[0].child[1].config_img = '';
        
        // DB.models[0].nodes[7].options[0].child.push(
        //     {
        //         config_img: data?.acf.rescue_mode_image.url
        //     }
            
        // );
    }else{
        DB.models[0].nodes[7].options[0].active = false;
    }


    if(data?.acf.engine_power_option){
        DB.models[0].nodes[0].active = true;
        data.acf.engine_power_option.map((a, b) => {
            DB.models[0].nodes[0].options[0].child.push(
                {
                    id: b,
                    active_id: "1.1."+b,
                    name: a.td_po_name,
                    desc: a.td_po_description,
                    active: b === 0 ? true : false
                }
            );
        })
    }else{
        DB.models[0].nodes[0].options[0].active = false;
    }

    if(data?.acf.tubes_tube_colour){
        DB.models[0].nodes[1].active = true;
        data.acf.tubes_tube_colour.map((a, b) => {
            DB.models[0].nodes[1].options[0].child.push(
                {
                    id: b,
                    active_id: "2.1."+b,
                    name: a.td_tube_name,
                    value: (a.td_tubes_colour1 !== a.td_tubes_colour2) ? "linear-gradient(180deg, "+a.td_tubes_colour1+" 0%, rgba(217, 217, 217, 0.00) 49.38%, "+a.td_tubes_colour2+" 100%)" : a.td_tubes_colour1,
                    config_img: a.td_tube_image,
                    active: b === 0 ? true : false,
                    icon: a.coloured_icon
                }
            );
        })
    }else{
        DB.models[0].nodes[1].options[0].active = false;
    }

    if(data?.acf.tubes_allow_logo){
        DB.models[0].nodes[1].active = true;
        DB.models[0].nodes[1].options[1].child[1].active = true;
    }
    else{
        DB.models[0].nodes[1].options[1].active = false;
    }
    
    if(data?.acf.tubes_inflation_options){
        DB.models[0].nodes[1].active = true;
        data.acf.tubes_inflation_options.map((a, b) => {
            DB.models[0].nodes[1].options[2].child.push(
                {
                    id: b,
                    active_id: "2.3."+b,
                    name: a.td_inf_opt_options,
                    value:  a.td_inf_opt_options,
                    active: b === 0 ? true : false,
                }
            );
        })
    }else{
        DB.models[0].nodes[1].options[2].active = false;
    }
    
    if(data?.acf.upholstery_colour){
        DB.models[0].nodes[2].active = true;
        data.acf.upholstery_colour.map((a, b) => {
            DB.models[0].nodes[2].options[0].child.push(
                {
                    id: b,
                    active_id: "3.1."+b,
                    name: a.td_up_name,
                    value: (a.td_up_colour1 !== a.td_up_colour2) ? "linear-gradient(180deg, "+a.td_up_colour1+" 0%, rgba(217, 217, 217, 0.00) 49.38%, "+a.td_up_colour2+" 100%)" : a.td_up_colour1,
                    config_img: a.td_up_coloured_image,
                    active: b === 0 ? true : false,
                    icon: a.coloured_up_icon
                }
            );
        })
    }else{
        DB.models[0].nodes[2].options[0].active = false;
    }

    if(data?.acf.td_up_allow_logo_copy){
        DB.models[0].nodes[2].active = true;
        DB.models[0].nodes[2].options[1].child[1].active = true;
    }
    else{
        DB.models[0].nodes[2].options[1].active = false;
    }
    
    if(data?.acf.td_gelcoat_helm){
        DB.models[0].nodes[3].active = true;
        data.acf.td_gelcoat_helm.map((a, b) => {
            DB.models[0].nodes[3].options[0].child.push(
                {
                    id: b,
                    active_id: "4.1."+b,
                    name: a.td_gelcoat_name,
                    value: (a.td_gelcoat_colour1 !== a.td_gelcoat_colour2) ? "linear-gradient(180deg, "+a.td_gelcoat_colour1+" 0%, rgba(217, 217, 217, 0.00) 49.38%, "+a.td_gelcoat_colour2+" 100%)" : a.td_gelcoat_colour1,
                    config_img: a.td_up_gelcoat_image,
                    active: b === 0 ? true : false,
                    icon: a.coloured_gelcoat_icon
                }
            );
        })
    }else{
        DB.models[0].nodes[3].options[0].active = false;
    }
    
    if(data?.acf.td_gelcoat_deck){
        DB.models[0].nodes[3].active = true;
        data.acf.td_gelcoat_deck.map((a, b) => {
            DB.models[0].nodes[3].options[1].child.push(
                {
                    id: b,
                    active_id: "4.2."+b,
                    name: a.td_deck_name,
                    value: (a.td_deck_colour1 !== a.td_deck_colour2) ? "linear-gradient(180deg, "+a.td_deck_colour1+" 0%, rgba(217, 217, 217, 0.00) 49.38%, "+a.td_deck_colour2+" 100%)" : a.td_deck_colour1,
                    config_img: a.td_up_deck_image,
                    active: b === 0 ? true : false,
                    icon: a.coloured_deck_icon
                }
            );
        })
    }else{
        DB.models[0].nodes[3].options[1].active = false;
    }
    
    if(data?.acf.td_gelcoat_hull){
        DB.models[0].nodes[3].active = true;
        data.acf.td_gelcoat_hull.map((a, b) => {
            DB.models[0].nodes[3].options[2].child.push(
                {
                    id: b,
                    active_id: "4.3."+b,
                    name: a.td_hull_name,
                    value: (a.td_hull_colour1 !== a.td_hull_colour2) ? "linear-gradient(180deg, "+a.td_hull_colour1+" 0%, rgba(217, 217, 217, 0.00) 49.38%, "+a.td_hull_colour2+" 100%)" : a.td_hull_colour1,
                    config_img: a.td_up_hull_image,
                    active: b === 0 ? true : false,
                    icon: a.coloured_hull_icon
                }
            );
        })
    }else{
        DB.models[0].nodes[3].options[2].active = false;
    }

    if(data?.acf.td_gelcoat_tube_pod){
        DB.models[0].nodes[3].active = true;
        data.acf.td_gelcoat_tube_pod.map((a, b) => {
            DB.models[0].nodes[3].options[3].child.push(
                {
                    id: b,
                    active_id: "4.4."+b,
                    name: a.td_tube_pod_name,
                    value: (a.td_tube_pod_colour1 !== a.td_tube_pod_colour2) ? "linear-gradient(180deg, "+a.td_tube_pod_colour1+" 0%, rgba(217, 217, 217, 0.00) 49.38%, "+a.td_tube_pod_colour2+" 100%)" : a.td_tube_pod_colour1,
                    config_img: a.td_up_tube_pod_image,
                    active: b === 0 ? true : false,
                    icon: a.coloured_tube_pod_icon
                }
            );
        })
    }else{
        DB.models[0].nodes[3].options[3].active = false;
    }
    
    if(data?.acf.deck_colour){
        DB.models[0].nodes[4].active = true;
        let i = 0;
        data.acf.deck_colour.map((a, b) => {
            if(a.td_deck_colour){
                a.td_deck_colour.map((c, d) => {
                    DB.models[0].nodes[4].options[0].child.push(
                        {
                            id: i,
                            active_id: "5.1."+(i+1),
                            name: c.td_deck_name,
                            value: (c.td_deck_colour1 !== c.td_deck_colour2) ? "linear-gradient(180deg, "+c.td_deck_colour1+" 0%, rgba(217, 217, 217, 0.00) 49.38%, "+c.td_deck_colour2+" 100%)" : c.td_deck_colour1,
                            config_img: c.td_up_deck_image,
                            active: i === 0 ? true : false,
                            icon: c.coloured_deck_icon
                        }
                    );
                    i++;
                })
            }
        })
    }else{
        DB.models[0].nodes[4].options[0].active = false;
    }
    
    if(data?.acf.weather_protection){
        DB.models[0].nodes[5].active = true;
        data.acf.weather_protection.map((a, b) => {
            DB.models[0].nodes[5].options[0].child.push(
                {
                    id: b,
                    active_id: "6.1."+b,
                    z_index: a.tb_wp_z_index,
                    category: a.tb_wp_category,
                    name: a.tb_wp_name,
                    value:  a.td_wp_icon_image ? a.td_wp_icon_image : '',
                    active: false,
                    config_img: a.td_wp_main_image ? a.td_wp_main_image : ''
                }
            );
        })
    }else{
        DB.models[0].nodes[5].options[0].active = false;
    }
    
    if(data?.acf.finishing_touches){
        DB.models[0].nodes[6].active = true;
        let i = 0;
        data.acf.finishing_touches.map((a, b) => {
            DB.models[0].nodes[6].options[b].name = a.tb_ft_name;
            DB.models[0].nodes[6].options[b].child = [];
            if (a.td_ft_nodes){
                a.td_ft_nodes.map((c, d) => {
                    DB.models[0].nodes[6].options[b].child.push(
                        {
                            id: i,
                            active_id: "7."+(b+1)+"."+(i+1),
                            name: c.td_ft_label,
                            value: c.td_ft_label,
                            info: c.td_ft_info,
                            img_src: c.td_ft_main_image ? c.td_ft_main_image : '',
                            active: false
                        }
                    );
                    i++;
                })
            }
        })
    }
    else{
        DB.models[0].nodes[6].options = [];
    }
}

export async function displayShareHandler (params, obj) {
    const { cat, model } = params;
    DB.code = '';
    
    const body = document.body
    body.classList.add('active_code_modal')

    const active_parts = [];
    obj.models[0].nodes?.map((m, n) => {
        m.options.map((a, b) => {
            a.child.map((c, d) => {
                if(c.active === true){
                    active_parts.push(c.active_id);
                }
            });
        });
    })

    try {            
        let flag = true;
        
        await getData2(process.env.REACT_APP_API_URL +"gf/v2/forms/3/entries").then((data) => {
            if (data.entries) {
                data.entries.map((x, y) => {
                    const active_decode = Object.values(x);

                    // Need to add check for category and model 
                    if(active_decode[0] === JSON.stringify(active_parts) && active_decode[2] === cat && active_decode[3] === model ){
                        flag = false;
                        DB.code = active_decode[1];
                    }
                })
            }
        });

        if(flag){
            const time = new Date().valueOf();
            DB.code = time;
            
            postData(process.env.REACT_APP_API_URL +"gf/v2/entries", { 'form_id': 3, 1: JSON.stringify(active_parts), 3 : time, 5: cat, 6: model, 7: JSON.stringify(obj.models) }).then((data) => {
                
            });
        }
    } catch (e) {
        console.error("Error saving code: ", e);
    }
}