import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Controller } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'bootstrap/dist/css/bootstrap.css';
import './Tenders.scss';
import { useEffect, useState } from 'react';
import FooterMenu from './componenets/FooterMenu';
import Header from './Header';
import { DB } from './store/store';
import { useSnapshot } from 'valtio';
import { Link, useParams } from 'react-router-dom';

const Tenders = () => {
    const params = useParams();
    const { label } = params;

    // const newLabel = decodeURIComponent(label);
    // const finalLabel = newLabel.replace(/\s+/g, '-');

    // const newLabel = decodeURIComponent(label);
    // const finalLabel = newLabel.replace(/_/g, ' ');

    const snap = useSnapshot(DB);
    const [swiperState, setSwiperState] = useState(null);
    const [slideActive, setSlideActive] = useState(null);

    const handleClick = (i) => {
        swiperState.slideTo(i);
        setSlideActive(i)
    }

    const tenders = [
        {
            id: 0,
            classname: "evojet-bg",
            config_image: "/images/tenders/evojet.png",
            tender_name: "EvoJet",
            tender_description: "131+ FT / 40+ M Yachts Up to 13 People",
            tender_button:[
                "EvoJet 70",
            ]
        },
        {
            id: 1,
            classname: "solas-bg",
            config_image: "/images/tenders/SOLAS.png",
            tender_name: "SOLAS",
            tender_description: "98+ FT / 30+ M Yachts 8 - 11 people",
            tender_button:[
                "SOLAS 505",
                "SOLAS 565",
                "SOLAS 625"
            ]
        },
        {
            id: 2,
            classname: "dieseljet-bg",
            config_image: "/images/tenders/dieselJet.png",
            tender_name: "DieselJet",
            tender_description: "82+ FT / 25+ M Yachts 6 - 11 People",
            tender_button:[
                "DieselJet 415",
                "DieselJet 445",
                "DieselJet 505",
                "DieselJet 565",
                "DieselJet 625"
            ]
        },
        {
            id: 3,
            classname: "sportjet-bg",
            config_image: "/images/tenders/sportJet.png",
            tender_name: "SportJet",
            tender_description: "65+ FT / 20+ M Yachts 5 - 7 People",
            tender_button:[
                "SportJet 345",
                "SportJet 395",
                "SportJet 435",
                "SportJet 460",
                "SportJet 520",
            ]
        },
        {
            id: 4,
            classname: "turbojet-bg",
            config_image: "/images/tenders/turboJet.png",
            tender_name: "TurboJet",
            tender_description: "40+ FT / 12+ M Yachts Up to 4 People",
            tender_button:[
                "TurboJet 285",
                "TurboJet 325",
            ]
        },
        {
            id: 5,
            classname: "minijet-bg",
            config_image: "/images/tenders/miniJet.png",
            tender_name: "MiniJet",
            tender_description: "38+ FT / 11+ M Yachts 3 + 1 People",
            tender_button:[
                "MiniJet 280",
            ]
        },
    ]

    return (
        <>
            {
                DB.API_ready && 
                <>
                    <div className="top-wrapper">
                        <Header />
                        <div className='tender max-wrap2 pb-5'>

                            <div className="content-area row">
                                <div className="col-12">
                                    <h3 className="">Build My Tender <span style={{ color: '#6B7074', display: 'block' }}>Making your Williams Jet Tender uniquely yours.</span>
                                    </h3>
                                </div>
                            </div>

                            <div className="tab-main-section row pb-2">
                                <div className="d-flex align-items-center tab-section-title col-m-12 col-lg-5">
                                    Let's get started by choosing a tender range and model.
                                </div>
                                <div className="tab-section-content col-md-12 col-lg-7">
                                    <div className="tab-section">
                                        {tenders.map((item, i) =>(
                                            <div key={i} onClick={()=>handleClick(item.id)} className={`ic1_tab ic1_tab-0 ${slideActive === item.id ? "active" : ""}`}> {item.tender_name} </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <Swiper
                                modules={[Navigation, Pagination, Controller]}
                                controller={{ control: swiperState }}
                                navigation
                                pagination={{ 
                                    clickable: true,
                                    renderBullet: function (index, className) {
                                        return '<span class="px-2 ' + className + '">' + 0 + (index + 1) + "</span>";
                                    }
                                }}
                                autoHeight={true}
                                spaceBetween={30}
                                slidesPerView={"auto"}
                                onSwiper={(swiper) => {
                                    setSwiperState(swiper);
                                    setSlideActive(swiper.activeIndex)
                                }}
                                onSlideChange={(e) => {
                                    setSlideActive(e.activeIndex)
                                }}
                            >
                                {tenders.map((item, i) =>(
                                    <SwiperSlide key={i} className={`${item.classname}`}>
                                        <div className="banner-content-section">
                                            <div className="banner-heading">
                                                <h4>{item.tender_name}</h4>
                                                <h3>{item.tender_description}</h3>
                                            </div>
                                            <div className="banner-buttons desktop-display">
                                                <p>Choose a model:</p>
                                                {item.tender_button.map((btn, i)=>{
                                                    if(label){
                                                        return(
                                                            <a key={i} href={`${label}/${item.tender_name}/${btn.trim().split(/\s* \s*/)[1]}`} className="btn btn_dark" onClick={() => {
                                                                DB.API_ready_for_tender = false;
                                                                DB.models[0].nodes[7].options[0].child.map((a)=> {
                                                                    a.active = false;
                                                                })
                                                            }}>{btn}</a>
                                                        )
                                                    }else{
                                                        return(
                                                            <a key={i} href={`${item.tender_name}/${btn.trim().split(/\s* \s*/)[1]}`} className="btn btn_dark" onClick={() => {
                                                                DB.API_ready_for_tender = false;
                                                                DB.models[0].nodes[7].options[0].child.map((a)=> {
                                                                    a.active = false;
                                                                })
                                                            }}>{btn}</a>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        <div className="banner-img-section">
                                            <img src={item.config_image} alt="" />
                                        </div>
                                        <div className="banner-buttons mobile-display">
                                            {item.tender_button.map((btn, i)=>{
                                                if(label){
                                                    return(
                                                        <a key={i} href={`${label}/${item.tender_name}/${btn.trim().split(/\s* \s*/)[1]}`} className="btn btn_dark" onClick={() => {
                                                            DB.API_ready_for_tender = false;
                                                            DB.models[0].nodes[7].options[0].child.map((a)=> {
                                                                a.active = false;
                                                            })
                                                        }}>{btn}</a>
                                                    )
                                                }else{
                                                    return(
                                                        <a key={i} href={`${item.tender_name}/${btn.trim().split(/\s* \s*/)[1]}`} className="btn btn_dark" onClick={() => {
                                                            DB.API_ready_for_tender = false;
                                                            DB.models[0].nodes[7].options[0].child.map((a)=> {
                                                                a.active = false;
                                                            })
                                                        }}>{btn}</a>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            <div className="ins-block">
                                <div className="ins-text">Need some inspiration?</div>
                                <Link to={`${process.env.REACT_APP_MAIN_SITE_URL}customisation-studio/`} className="button btn btn btn_dark btn_customize text-capitalize w-100">Visit the Customisation Studio</Link>
                            </div>
                        </div>
                    </div>
                    <FooterMenu />
                </>
            }

            {
                !DB.API_ready && 
                <div className="d-flex justify-content-center align-items-center flex-column" style={{height: "100vh"}}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="pt-4">&nbsp;&nbsp; Loading...</div>
                </div>
            }
        </>
        
    )
}

export default Tenders